import { letterDistribution, wordList } from '../data';
import { WordValidation } from '../types';
export const useGame = () => {
  const getRandomLetter = () => {
    const randomIndex = Math.floor(Math.random() * 113); // There are 112 letters in the letter distribution
    return letterDistribution[randomIndex] || 'E';
  };

  const getPointValue = (wordLength: number) => {
    switch (wordLength) {
      case 0:
        return 0;
      case 1:
        return 0;
      case 2:
        return 3;
      case 3:
        return 6;
      case 4:
        return 9;
      case 5:
        return 14;
      case 6:
        return 20;
      case 7:
        return 32;
      case 8:
        return 48;
      case 9:
        return 72;
      case 10:
        return 100;
      default:
        return 100;
    }
  };

  const validateWord = (playerWord: string): WordValidation => {
    let response = {
      isValid: false,
      pointValue: 0,
    };
    if (wordList.includes(playerWord?.toLowerCase())) {
      response.isValid = true;
      response.pointValue = getPointValue(playerWord?.length || 0);
    }

    return response;
  };

  const getGameStatsKeyName = (wordLength: number): string => {
    const santizedWordLength = wordLength > 10 ? 10 : wordLength;

    const map = new Map();
    map.set(2, 'twoLetterWordCount');
    map.set(3, 'threeLetterWordCount');
    map.set(4, 'fourLetterWordCount');
    map.set(5, 'fiveLetterWordCount');
    map.set(6, 'sixLetterWordCount');
    map.set(7, 'sevenLetterWordCount');
    map.set(8, 'eightLetterWordCount');
    map.set(9, 'nineLetterWordCount');
    map.set(10, 'tenPlusLetterWordCount');
    return map.get(santizedWordLength) || '';
  };
  return {
    getGameStatsKeyName,
    getRandomLetter,
    validateWord,
  };
};
