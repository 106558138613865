/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  useIonLoading,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { gameData, gameStats, gameTime, userState } from '../atoms';
import { initialGameStats } from '../data';
import { getIdTokenFromLocalStorage } from '../firebase';
import { useAxios, useGuestStats } from '../hooks';
import { GameStatus } from '../types';
import {
  GAME_DURATION_IN_SECONDS,
  SAVING_GAME_STATS_MESSAGE,
  STARTING_NEW_GAME,
} from '../utils/constants';
import PostgameShareButton from './PostgameShareButton';

const PostgameReport: React.FC = () => {
  const [gameState, setGameState] = useRecoilState(gameData);
  const [gameTimeState, setGameTimeState] = useRecoilState(gameTime);
  // const [myStatsState] = useRecoilState(myStats);
  const [gameStatsState, setGameStatsState] = useRecoilState(gameStats);
  const [userData] = useRecoilState(userState);
  const [gameIsOver, setGameIsOver] = useState(false);
  const { addScoreToGuestStats } = useGuestStats();

  const { gameId, score } = gameState;
  // const { bestScore } = myStatsState;
  const {
    twoLetterWordCount,
    threeLetterWordCount,
    fourLetterWordCount,
    fiveLetterWordCount,
    sixLetterWordCount,
    sevenLetterWordCount,
    eightLetterWordCount,
    nineLetterWordCount,
    tenPlusLetterWordCount,
  } = gameStatsState;

  const isUserLoggedIn = !!userData?.username;

  const [presentLoader, dismissLoader] = useIonLoading();

  const [{ data: newGameData }, createGame] = useAxios(
    {
      url: '/game',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getIdTokenFromLocalStorage()}`,
      },
    },
    { manual: true }
  );

  const [{ loading, error }, completeGame] = useAxios(
    {
      url: '/game',
      method: 'PUT',
      data: {
        gameId,
        score,
      },
      headers: {
        Authorization: `Bearer ${getIdTokenFromLocalStorage()}`,
      },
    },
    {
      manual: true,
    }
  );

  // Save the completed game as soon as this component mounts
  useEffect(() => {
    if (isUserLoggedIn && score && gameId) {
      const controller = new AbortController();

      completeGame({
        signal: controller.signal,
      });

      return () => {
        // cancel the request before component unmounts
        controller.abort();
      };
    } else if (!isUserLoggedIn) {
      addScoreToGuestStats(score);
    }
  }, []);

  // Handle loader for saving the completed game
  useEffect(() => {
    if (isUserLoggedIn) {
      if (loading && !error) {
        presentLoader({
          message: SAVING_GAME_STATS_MESSAGE,
        });
      } else {
        dismissLoader();
      }
    }
  }, [loading, error]);

  // After a new game has been created, update the game state with the new game ID and set the GameStatus to Active in order to show the game board
  useEffect(() => {
    if (isUserLoggedIn && newGameData) {
      dismissLoader();

      setGameState({
        ...gameState,
        currentWord: '',
        gameId: newGameData?.gameId,
        score: 0,
        status: GameStatus.Active,
      });

      setGameTimeState({
        ...gameTimeState,
        secondsLeft: GAME_DURATION_IN_SECONDS,
      });

      setGameStatsState({
        ...initialGameStats,
      });
    }
  }, [newGameData]);

  // If not logged in, use component state to trigger global state update
  useEffect(() => {
    if (!isUserLoggedIn && gameIsOver) {
      setGameState({
        ...gameState,
        currentWord: '',
        score: 0,
        status: GameStatus.Active,
      });

      setGameTimeState({
        ...gameTimeState,
        secondsLeft: GAME_DURATION_IN_SECONDS,
      });

      setGameStatsState({
        ...initialGameStats,
      });
    }
  }, [gameIsOver]);

  const startGame = async () => {
    if (isUserLoggedIn) {
      // Clear the gameId after the game is completed.
      setGameState({
        ...gameState,
        gameId: '',
      });

      presentLoader({
        message: STARTING_NEW_GAME,
      });

      await createGame();
      await dismissLoader();
    } else {
      // If not logged in, immediately set the game status to Active to go back to the game board
      setGameIsOver(true);
    }
  };

  // TODO - This feature only works if the user's best score has already been fetched and is up to date. Consider scratching this idea.
  // const newBestScore = () => {
  //   if (isUserLoggedIn && bestScore && score > bestScore) {
  //     return (
  //       <div className="new-best-score">
  //         <p>Congrats! You've got a new best score!</p>
  //       </div>
  //     );
  //   }
  // };

  const totalWordCount = () => {
    return (
      twoLetterWordCount +
      threeLetterWordCount +
      fourLetterWordCount +
      fiveLetterWordCount +
      sixLetterWordCount +
      sevenLetterWordCount +
      eightLetterWordCount +
      nineLetterWordCount +
      tenPlusLetterWordCount
    );
  };

  return (
    <IonGrid>
      <IonRow>
        <IonCol sizeMd="8" offsetMd="2">
          <IonCard className="ion-text-center ion-padding">
            <IonCardTitle>Postgame Report</IonCardTitle>
            <IonCardContent>
              {/* {newBestScore()} */}
              <IonList>
                <IonItem style={{ borderBottom: '1px solid #ffffff' }}>
                  <IonLabel>
                    <strong>Score:</strong>
                  </IonLabel>
                  <strong>{score}</strong>
                </IonItem>
                <IonItem>
                  <IonLabel>2-Letter Words:</IonLabel>
                  {twoLetterWordCount}
                </IonItem>
                <IonItem>
                  <IonLabel>3-Letter Words:</IonLabel>
                  {threeLetterWordCount}
                </IonItem>
                <IonItem>
                  <IonLabel>4-Letter Words:</IonLabel>
                  {fourLetterWordCount}
                </IonItem>
                <IonItem>
                  <IonLabel>5-Letter Words:</IonLabel>
                  {fiveLetterWordCount}
                </IonItem>
                <IonItem>
                  <IonLabel>6-Letter Words:</IonLabel>
                  {sixLetterWordCount}
                </IonItem>
                <IonItem>
                  <IonLabel>7-Letter Words:</IonLabel>
                  {sevenLetterWordCount}
                </IonItem>
                <IonItem>
                  <IonLabel>8-Letter Words:</IonLabel>
                  {eightLetterWordCount}
                </IonItem>
                <IonItem>
                  <IonLabel>9-Letter Words:</IonLabel>
                  {nineLetterWordCount}
                </IonItem>
                <IonItem>
                  <IonLabel>10+-Letter Words:</IonLabel>
                  {tenPlusLetterWordCount}
                </IonItem>
                <IonItem style={{ borderTop: '1px solid #ffffff' }}>
                  <IonLabel>
                    <strong>Total Words:</strong>
                  </IonLabel>
                  <strong>{totalWordCount()}</strong>
                </IonItem>
              </IonList>
            </IonCardContent>
            <div>
              <IonButton
                color="primary"
                onClick={startGame}
                className="ion-margin-end"
              >
                Start New Game
              </IonButton>
              <PostgameShareButton score={score} />
            </div>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default PostgameReport;
