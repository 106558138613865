import { GameStatus } from '../types';
import { GAME_DURATION_IN_SECONDS } from '../utils/constants';

export const initialMyStats = {
  avgScore: 0,
  bestScore: 0,
  gamesPlayed: 0,
};

export const initialLeaderboardData = {
  leaderboardData: [
    {
      bestScore: 0,
      username: '-',
    },
  ],
};

export const initialGameState = {
  currentWord: '',
  gameId: '',
  score: 0,
  status: GameStatus.PreGame,
};

export const mockUserstate = {
  email: 'tester@test.com',
  username: 'tester12',
};

export const initialGameTime = {
  secondsLeft: GAME_DURATION_IN_SECONDS,
};

export const initialGameStats = {
  twoLetterWordCount: 0,
  threeLetterWordCount: 0,
  fourLetterWordCount: 0,
  fiveLetterWordCount: 0,
  sixLetterWordCount: 0,
  sevenLetterWordCount: 0,
  eightLetterWordCount: 0,
  nineLetterWordCount: 0,
  tenPlusLetterWordCount: 0,
};
