import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { ModalProps } from '../types';

const HowToPlay: React.FC<ModalProps> = (props: ModalProps) => {
  const { closeHandler } = props;

  return (
    <>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonTitle>How to Play</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => closeHandler()}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div>
          <ul>
            <li>
              The game board contains letters that you must use to build words.
              Click or tap a letter to add it to your current word.
            </li>
            <li>
              When you have a word ready, click or tap the{' '}
              <strong className="success-color">Submit</strong> button to enter
              it.
            </li>
            <li>
              Once the word is submitted, you'll be given points if the word is
              an actual, valid word. See the{' '}
              <strong className="tertiary-color">Scoring Guide</strong> for more
              info on this.
            </li>
            <li>
              After a valid word is submitted, your current word will be cleared
              and you can begin building a new word.
            </li>
            <li>
              You can clear your current word at any time by clicking or tapping
              the <strong className="tertiary-color">Clear</strong> button. Use
              this button if you make a mistake while building a word.
            </li>
            <li>
              Each game lasts 2 minutes. The goal is to earn as many points as
              you can in the allotted time.
            </li>
            <li>
              Your game stats are saved at the end of each game. Head over to
              the{' '}
              <strong className="tertiary-color">Stats & Leaderboard</strong>{' '}
              page to see your play results and the global leaderboard of the
              Top 500 scores.
            </li>
            <li>
              You do not need to create an account to play{' '}
              <strong className="primary-color">Words Against The Clock</strong>
              . When you play as a guest, however, your game stats will not be
              saved to our servers, and will instead only be saved to your
              browser. Likewise, while playing as guest, your scores will not be
              eligible to be put on the{' '}
              <strong className="tertiary-color">Leaderboard</strong>.
            </li>
          </ul>
        </div>
      </IonContent>
    </>
  );
};

export default HowToPlay;
