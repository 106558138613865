import { GUEST_STATS_LOCALSTORAGE_KEY } from '../utils/constants';

export const useGuestStats = () => {
  const addScoreToGuestStats = (score: number) => {
    const currentStats = getGuestStats();

    if (currentStats.gamesPlayed) {
      // If guest stats already exist, then add to them.
      currentStats.scores.push(score);

      const newScores = currentStats.scores;
      const newGamesPlayed = currentStats.gamesPlayed + 1;
      const newBestScore =
        score > currentStats.bestScore ? score : currentStats.bestScore;

      let lifetimeScore = 0;
      newScores?.forEach((score: number) => {
        lifetimeScore += Number(score);
      });

      const newAvgScore = Math.round(lifetimeScore / newGamesPlayed);

      localStorage.setItem(
        GUEST_STATS_LOCALSTORAGE_KEY,
        JSON.stringify({
          avgScore: newAvgScore,
          bestScore: newBestScore,
          gamesPlayed: newGamesPlayed,
          scores: newScores,
        })
      );
    } else {
      // This would be the player's first game (i.e. nothing saved in local storage.)
      localStorage.setItem(
        GUEST_STATS_LOCALSTORAGE_KEY,
        JSON.stringify({
          avgScore: score,
          bestScore: score,
          gamesPlayed: 1,
          scores: [score],
        })
      );
    }
  };

  const getGuestStats = () => {
    const stats = localStorage.getItem(GUEST_STATS_LOCALSTORAGE_KEY);

    return stats ? JSON.parse(stats) : {};
  };

  return {
    addScoreToGuestStats,
    getGuestStats,
  };
};
