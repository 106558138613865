import { atom } from 'recoil';
import { initialGameState, initialGameStats, initialGameTime } from '../data';
import { GameState, GameStats, GameTime } from '../types';

export const gameData = atom({
  key: 'gameState',
  default: initialGameState as GameState,
});

export const gameTime = atom({
  key: 'gameTime',
  default: initialGameTime as GameTime,
});

export const gameStats = atom({
  key: 'gameStats',
  default: initialGameStats as GameStats,
});
