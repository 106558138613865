/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonRow,
  useIonLoading,
} from '@ionic/react';
import { useRecoilState } from 'recoil';
import { gameData, gameStats, gameTime, userState } from '../atoms';
import { initialGameStats } from '../data';
import { getIdTokenFromLocalStorage } from '../firebase';
import { useAxios } from '../hooks';
import { GameStatus } from '../types';
import {
  GAME_DURATION_IN_SECONDS,
  GUEST_DISCLAIMER,
  PREGAME_INSTRUCTION,
  STARTING_NEW_GAME,
} from '../utils/constants';

const PregameCard: React.FC = () => {
  const [gameState, setGameState] = useRecoilState(gameData);
  const [gameTimeState, setGameTimeState] = useRecoilState(gameTime);
  const [, setGameStatsState] = useRecoilState(gameStats);
  const [userData] = useRecoilState(userState);
  const [presentLoader, dismissLoader] = useIonLoading();

  const isUserLoggedIn = !!userData?.username;

  const [, createGame] = useAxios(
    {
      url: '/game',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getIdTokenFromLocalStorage()}`,
      },
    },
    { manual: true }
  );

  const startGame = async () => {
    let newGameData;

    presentLoader({
      message: STARTING_NEW_GAME,
    });

    if (isUserLoggedIn) {
      newGameData = await createGame();
    } else {
      // Wait a second to ensure that the timer from a previous game (if ended early) does not
      // overlap with the timer in a new game.
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    await dismissLoader();

    setGameTimeState({
      ...gameTimeState,
      secondsLeft: GAME_DURATION_IN_SECONDS,
    });

    setGameState({
      ...gameState,
      currentWord: '',
      gameId: newGameData?.data?.gameId,
      score: 0,
      status: GameStatus.Active,
    });

    setGameStatsState({
      ...initialGameStats,
    });
  };
  return (
    <IonGrid>
      <IonRow>
        <IonCol
          size="10"
          offset="1"
          sizeMd="6"
          offsetMd="3"
          sizeLg="4"
          offsetLg="4"
        >
          <IonCard className="ion-text-center ion-padding">
            <IonCardContent>
              {userData?.username ? null : (
                <div className="ion-padding-bottom">
                  <p>
                    <em>{GUEST_DISCLAIMER}</em>
                  </p>
                </div>
              )}
              <p>{PREGAME_INSTRUCTION}</p>
            </IonCardContent>
            <div>
              <IonButton color="primary" onClick={startGame}>
                Start Game
              </IonButton>
            </div>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default PregameCard;
