import { IonButton, IonIcon } from '@ionic/react';
import { shareSocial } from 'ionicons/icons';
import { PostgameShareButtonProps } from '../types';

const PostgameShareButton: React.FC<PostgameShareButtonProps> = (
  props: PostgameShareButtonProps
) => {
  const { score } = props;

  const openShareMenu = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Words Against The Clock',
          text: `I just scored ${score} points in a game of Words Against The Clock! See if you can beat that! #wordsagainsttheclock`,
          url: 'https://wordsagainsttheclock.com',
        })
        .then(() => {
          console.log('Opening the share menu...');
        })
        .catch(console.error);
    } else {
      return null;
    }
  };

  return (
    <IonButton color="success" onClick={openShareMenu}>
      <IonIcon icon={shareSocial} />
      Share
    </IonButton>
  );
};

export default PostgameShareButton;
