import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  deleteUser,
} from 'firebase/auth';
import { getFirestore, collection, addDoc } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCo8h32oj6jz_HtmzMtbwngS98kXXsfIAQ',
  authDomain: 'words-against-the-clock.firebaseapp.com',
  projectId: 'words-against-the-clock',
  storageBucket: 'words-against-the-clock.appspot.com',
  messagingSenderId: '51008679142',
  appId: '1:51008679142:web:af85a6a0674a5d87a59f27',
  measurementId: 'G-SMBK41ERQ4',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

// Initialize Analytics and get a reference to the service
// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app);

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
    await setIdTokenInLocalStorage();
    return {
      status: 'SUCCESS',
    };
  } catch (err) {
    console.error(err);
    return {
      status: 'ERROR',
    };
  }
};

// Consider moving the "addDoc" logic to a Firebase Function
const registerWithEmailAndPassword = async (username, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, 'users'), {
      uid: user.uid,
      username,
      authProvider: 'local',
      email,
    });
    // await setIdTokenInLocalStorage(); // For now, don't make the registration process log in a user
    return {
      status: 'SUCCESS',
    };
  } catch (err) {
    console.error(err);
    return {
      status: 'ERROR',
      message: err.message,
    };
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return { status: 'SUCCESS' };
  } catch (err) {
    console.error(err);
    return { status: 'ERROR' };
  }
};

const firebaseLogout = async () => {
  await signOut(auth);
};

const deleteUserAccount = async () => {
  try {
    const currentUser = auth.currentUser;
    if (currentUser) {
      await deleteUser(currentUser);
      return { status: 'SUCCESS' };
    }
  } catch (err) {
    console.error('Error deleting user', err);
    return { status: 'ERROR' };
  }
};

// LocalStorage functions
const setIdTokenInLocalStorage = async () => {
  const idToken = await auth.currentUser.getIdToken();
  localStorage.setItem('idToken', idToken);
};

const getIdTokenFromLocalStorage = () => {
  return localStorage.getItem('idToken') || '';
};

const removeIdTokenFromLocalStorage = () => {
  localStorage.removeItem('idToken');
};

export {
  auth,
  db,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  firebaseLogout,
  deleteUserAccount,
  setIdTokenInLocalStorage,
  getIdTokenFromLocalStorage,
  removeIdTokenFromLocalStorage,
};
