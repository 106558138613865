export interface ModalProps {
  closeHandler: Function;
}

export interface MyStatsState {
  avgScore: number;
  bestScore: number;
  gamesPlayed: number;
}

export interface LeaderboardItem {
  bestScore: number;
  username: string;
}

export interface LeaderboardState {
  leaderboardData: LeaderboardItem[];
}

export interface GameState {
  currentWord: string;
  gameId: string;
  score: number;
  status: GameStatus;
}

export enum GameStatus {
  Active = 'ACTIVE',
  PreGame = 'PRE_GAME',
  PostGame = 'POST_GAME',
  EndEarly = 'END_EARLY',
}

export interface GameTime {
  secondsLeft: number;
}

// Metadata about the game
export interface GameStats {
  twoLetterWordCount: number;
  threeLetterWordCount: number;
  fourLetterWordCount: number;
  fiveLetterWordCount: number;
  sixLetterWordCount: number;
  sevenLetterWordCount: number;
  eightLetterWordCount: number;
  nineLetterWordCount: number;
  tenPlusLetterWordCount: number;
}

export enum StatsTab {
  Leaderboard = 'LEADERBOARD',
  MyStats = 'MY_STATS',
}

export interface UserState {
  email: string;
  username: string;
}

export interface WordValidation {
  isValid: boolean;
  pointValue: number;
}

export interface PostgameShareButtonProps {
  score: number;
}
