const LegalLinks: React.FC = () => {
  return (
    <div className="ion-text-center">
      <div className="ion-padding-top">
        <a href="/privacy-policy.html" target="_blank">
          Privacy Policy
        </a>
      </div>
      <div className="ion-padding-top">
        <a href="/terms-of-service.html" target="_blank">
          Terms of Service
        </a>
      </div>
    </div>
  );
};
export default LegalLinks;
