import { useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { Leaderboard, MyStats } from '../components';
import { StatsTab } from '../types';
import { useRecoilState } from 'recoil';
import { userState } from '../atoms';
import { personCircle } from 'ionicons/icons';

const Stats: React.FC = () => {
  const [activeView, setActiveView] = useState(StatsTab.MyStats);
  const [user] = useRecoilState(userState);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <IonIcon
              className="header__player-icon"
              size="large"
              icon={personCircle}
            />
            <IonLabel className="header__player-name-label">
              {user.username || 'Guest'}
            </IonLabel>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonTitle size="large">{`Stats & Leaderboard`}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="ion-padding-vertical">
          <IonButtons>
            <IonButton
              color={activeView === StatsTab.MyStats ? 'secondary' : 'dark'}
              style={{ borderRight: '2px solid white', paddingRight: '6px' }}
              onClick={() => setActiveView(StatsTab.MyStats)}
            >
              My Stats
            </IonButton>
            <IonButton
              color={activeView === StatsTab.Leaderboard ? 'secondary' : 'dark'}
              onClick={() => setActiveView(StatsTab.Leaderboard)}
            >
              Leaderboard
            </IonButton>
          </IonButtons>
        </div>
        {activeView === StatsTab.Leaderboard ? <Leaderboard /> : <MyStats />}
      </IonContent>
    </IonPage>
  );
};

export default Stats;
