import { IonGrid, IonRow, IonCol, IonButton, useIonAlert } from '@ionic/react';
import { useRecoilState } from 'recoil';
import { gameData, gameStats } from '../atoms';
import { useGame } from '../hooks';
import { GameStats } from '../types';
import { playAudio } from '../utils';

const CurrentWordBar: React.FC = () => {
  const [gameState, setGameState] = useRecoilState(gameData);
  const [gameStatsState, setGameStatsState] = useRecoilState(gameStats);
  const { currentWord, score } = gameState;
  const { getGameStatsKeyName, validateWord } = useGame();
  const [presentAlert] = useIonAlert();

  const clearCurrentWord = () => {
    playAudio('clear-word-sound-audio-tag-1');
    setGameState({
      ...gameState,
      currentWord: '',
    });
  };

  const submitWord = () => {
    const submission = validateWord(currentWord);
    if (submission.isValid) {
      playAudio('success-sound-audio-tag-1');
      updateGameStats();

      setGameState({
        ...gameState,
        currentWord: '',
        score: score + submission.pointValue,
      });
    } else {
      playAudio('invalid-word-sound-audio-tag-1');
      presentAlert({
        header: 'Alert',
        message: "That's not in the word list.",
        buttons: ['Try Something Else'],
      });
    }
  };

  const updateGameStats = () => {
    const key = getGameStatsKeyName(currentWord?.length);
    const value = gameStatsState[key as keyof GameStats];
    setGameStatsState({
      ...gameStatsState,
      [key]: value + 1,
    });
  };

  return (
    <IonGrid>
      <IonRow>
        <IonCol sizeMd="8" offsetMd="2" sizeLg="6" offsetLg="3">
          <div className="current-word-bar">
            <IonRow>
              <IonCol>
                <div className="current-word-box">
                  {gameState?.currentWord || ''}
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="5" offset="0">
                <IonButton
                  color="tertiary"
                  expand="block"
                  size="default"
                  strong={true}
                  onClick={clearCurrentWord}
                >
                  Clear
                </IonButton>
              </IonCol>
              <IonCol size="5" offset="2">
                <IonButton
                  color="success"
                  expand="block"
                  size="default"
                  strong={true}
                  onClick={submitWord}
                >
                  Submit
                </IonButton>
              </IonCol>
            </IonRow>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default CurrentWordBar;
