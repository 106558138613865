/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonList,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from '@ionic/react';
import { mail } from 'ionicons/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LegalLinks } from '../components';
import { sendPasswordReset } from '../firebase';
import { enterKeyHandler } from '../utils';

const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const history = useHistory();
  const [presentAlert] = useIonAlert();

  const sendResetPasswordEmail = async () => {
    if (!email) {
      presentAlert({
        header: 'Alert',
        message: 'Please enter your email address',
        buttons: ['OK'],
      });
      return;
    }
    const res = await sendPasswordReset(email);
    const message =
      res.status === 'SUCCESS'
        ? 'Reset Password Email Sent!'
        : 'An error occurred';
    const header = res.status === 'SUCCESS' ? 'Success' : 'Alert';
    presentAlert({
      header,
      message,
      buttons: [{ text: 'OK', handler: () => history.push('/home') }],
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Reset Password</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonImg
          src={'assets/icon/watc-text-logo.png'}
          alt="Words Against The Clock logo"
          className="watc-logo"
        />
        <IonGrid>
          <IonRow>
            <IonCol
              size="10"
              offset="1"
              sizeMd="6"
              offsetMd="3"
              sizeLg="4"
              offsetLg="4"
            >
              <p>
                We can send you an email that you can use to reset your password
              </p>
              <IonList>
                <IonItem>
                  <IonInput
                    type="email"
                    name="email"
                    value={email}
                    onIonChange={(e) =>
                      setEmail((e.target as HTMLInputElement).value)
                    }
                    placeholder="E-mail Address"
                    onKeyUp={(e) =>
                      enterKeyHandler(e.key, sendResetPasswordEmail)
                    }
                  />
                </IonItem>
                <IonItem>
                  <IonButton size="default" onClick={sendResetPasswordEmail}>
                    <IonIcon icon={mail} />
                    Send Email
                  </IonButton>
                </IonItem>
              </IonList>
              <LegalLinks />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ResetPassword;
