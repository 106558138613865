/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
} from '@ionic/react';
import { refresh } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { AmazonAd } from '.';
import { useGuestStats } from '../hooks';
import { ENABLE_AMAZON_ADS, GUEST_STATS_DISCLAIMER } from '../utils/constants';

const GuestStats: React.FC = () => {
  const { getGuestStats } = useGuestStats();
  const [guestStats, setGuestStats] = useState(getGuestStats());

  useEffect(() => {
    setGuestStats(getGuestStats());
  }, []);

  const refreshGuestStats = () => {
    const stats = getGuestStats();
    setGuestStats(stats);
  };

  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            My Stats
            <IonButton
              color="tertiary"
              className="stats-table__refresh-button"
              onClick={() => refreshGuestStats()}
            >
              <IonIcon icon={refresh} />
            </IonButton>
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonCol>
                <p className="stats-table__header">Best Score</p>
                <p className="mystats-row">{guestStats?.bestScore || 0}</p>
              </IonCol>
              <IonCol>
                <p className="stats-table__header">Average Score</p>
                <p className="mystats-row">{guestStats?.avgScore || 0}</p>
              </IonCol>
              <IonCol>
                <p className="stats-table__header">Games Played</p>
                <p className="mystats-row">{guestStats?.gamesPlayed || 0}</p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <em>{GUEST_STATS_DISCLAIMER}</em>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>
      {ENABLE_AMAZON_ADS ? (
        <div className="ion-padding" style={{ borderTop: '1px solid gray' }}>
          <AmazonAd />
        </div>
      ) : null}
    </>
  );
};

export default GuestStats;
