import { atom } from 'recoil';
import { UserState } from '../types';

export const userState = atom({
  key: 'user',
  default: {
    email: '',
    username: '',
  } as UserState,
});
