export const GAME_DURATION_IN_SECONDS = 120; // Should be 120, but change this for testing purposes.
export const LOADING_MESSAGE = 'Loading...';
export const CREATE_ACCOUNT_LOADING_MESSAGE = 'Creating a new account...';
export const GUEST_DISCLAIMER =
  "You're playing as a guest. In order for us to save your scores and be eligible for the Leaderboard, you must log in.";
export const GUEST_STATS_DISCLAIMER =
  "You're playing as a guest. Your stats above reflect the games that have been played on this browser while not logged into an account.";
export const PREGAME_INSTRUCTION =
  "When you're ready to start a new game, press the button below.";
export const SAVING_GAME_STATS_MESSAGE = 'Saving game stats...';
export const STARTING_NEW_GAME = 'Starting a new game...';
export const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9_.-]*$/;
export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const GUEST_STATS_LOCALSTORAGE_KEY = 'watc-guest-stats';
export const ENABLE_AMAZON_ADS = true;
