/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonList,
  IonRow,
  useIonAlert,
  useIonLoading,
} from '@ionic/react';
import { logIn } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { userState } from '../atoms';
import {
  getIdTokenFromLocalStorage,
  logInWithEmailAndPassword,
} from '../firebase';
import { useAxios } from '../hooks';
import { LOADING_MESSAGE } from '../utils/constants';
import { enterKeyHandler } from '../utils';
import { LegalLinks } from '.';
import { Link } from 'react-router-dom';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginResult, setLoginResult] = useState('');
  const [, setUser] = useRecoilState(userState);
  const [presentAlert] = useIonAlert();
  const [presentLoader, dismissLoader] = useIonLoading();

  const [{ data: userData, error: getUserError }, executeGetUser] = useAxios(
    {
      url: `/user`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getIdTokenFromLocalStorage()}`,
      },
    },
    { manual: true }
  );

  const handleLogin = async () => {
    if (!email) {
      presentAlert({
        header: 'Alert',
        message: 'Please enter your email address',
        buttons: ['OK'],
      });
      return;
    } else if (!password) {
      presentAlert({
        header: 'Alert',
        message: 'Please enter a password',
        buttons: ['OK'],
      });
      return;
    }

    // Show the loading spinner
    presentLoader({
      message: LOADING_MESSAGE,
    });

    const result = await logInWithEmailAndPassword(email, password);
    setLoginResult(result.status);
  };

  useEffect(() => {
    if (loginResult === 'SUCCESS') {
      // Set user state
      executeGetUser().catch(async (error) => {
        console.log(error);
        await dismissLoader();
      });
    } else if (loginResult === 'ERROR') {
      dismissLoader();

      presentAlert({
        header: 'Alert',
        message: 'Something went wrong. Please try again.',
        buttons: ['OK'],
      });
    }
    setLoginResult(''); // Reset this to a neutral value after login attempt, so that subsequent attempts can be handled correctly
  }, [loginResult]);

  useEffect(() => {
    if (userData?.user?.email && userData?.user?.username) {
      setUser(userData?.user);
      dismissLoader();
    } else if (getUserError) {
      dismissLoader();

      presentAlert({
        header: 'Alert',
        message: 'Something went wrong. Please try again.',
        buttons: ['OK'],
      });
    }
  }, [userData]);

  return (
    <IonGrid>
      <IonRow>
        <IonCol
          size="10"
          offset="1"
          sizeMd="6"
          offsetMd="3"
          sizeLg="4"
          offsetLg="4"
        >
          <IonList>
            <IonItem>
              <IonInput
                type="email"
                name="email"
                value={email}
                onIonChange={(e) =>
                  setEmail((e.target as HTMLInputElement).value)
                }
                placeholder="E-mail Address"
                autocomplete="off"
                onKeyUp={(e) => enterKeyHandler(e.key, handleLogin)}
              />
            </IonItem>
            <IonItem>
              <IonInput
                type="password"
                value={password}
                onIonChange={(e) =>
                  setPassword((e.target as HTMLInputElement).value)
                }
                placeholder="Password"
                autocomplete="off"
                onKeyUp={(e) => enterKeyHandler(e.key, handleLogin)}
              />
            </IonItem>
            <IonItem>
              <IonButton size="default" onClick={handleLogin}>
                <IonIcon icon={logIn} />
                Login
              </IonButton>
            </IonItem>
          </IonList>
          <div className="ion-padding-top ion-text-center">
            <p>
              Don't have an account? &nbsp;
              <Link to={'/create-account'}>Create Account</Link>
            </p>
            <p>
              Forgot your password? &nbsp;
              <Link to={'/reset-password'}>Reset Password</Link>
            </p>
          </div>
          <LegalLinks />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default Login;
