import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonList,
  IonRow,
  IonText,
  useIonActionSheet,
  useIonAlert,
} from '@ionic/react';
import { logOut, trash } from 'ionicons/icons';
import { useRecoilState } from 'recoil';
import { userState } from '../atoms';
import {
  deleteUserAccount,
  firebaseLogout,
  removeIdTokenFromLocalStorage,
} from '../firebase';
import { LegalLinks } from './';

const Logout: React.FC = () => {
  const [user, setUser] = useRecoilState(userState);
  const [presentActionSheet] = useIonActionSheet();
  const [presentAlert] = useIonAlert();

  const logoutHandler = async () => {
    setUser({
      email: '',
      username: '',
    });
    removeIdTokenFromLocalStorage();
    await firebaseLogout();
    window.location.reload();
  };

  const deleteAccountHandler = async () => {
    const deleteAccountResult = await deleteUserAccount();

    if (deleteAccountResult?.status === 'SUCCESS') {
      removeIdTokenFromLocalStorage();
      presentAlert({
        header: 'Success',
        message: 'Your account has been deleted!',
        buttons: [
          {
            text: 'OK',
            handler: () => window.location.reload(),
          },
        ],
      });
    } else {
      presentAlert({
        header: 'Alert',
        message: 'Something went wrong. Please try again.',
        buttons: ['OK'],
      });
    }
  };

  return (
    <IonGrid>
      <IonRow>
        <IonCol
          size="10"
          offset="1"
          sizeMd="6"
          offsetMd="3"
          sizeLg="4"
          offsetLg="4"
        >
          <IonList>
            <IonItem>
              <p>
                You're playing as{' '}
                <IonText color="secondary"> {user.username}</IonText>{' '}
              </p>
            </IonItem>
            <IonItem
              className="ion-padding-bottom "
              style={{ borderBottom: '2px solid white' }}
            >
              <IonButton size="default" color="primary" onClick={logoutHandler}>
                <IonIcon icon={logOut} />
                Logout
              </IonButton>
            </IonItem>
            <IonItem className="ion-padding-top">
              <IonButton
                size="default"
                color="danger"
                onClick={() => {
                  presentActionSheet(
                    [
                      {
                        text: 'Delete My Account',
                        role: 'destructive',
                        handler: () => deleteAccountHandler(),
                        cssClass: 'action-sheet-button--delete-account',
                      },
                      {
                        text: 'Cancel',
                        role: 'cancel',
                      },
                    ],
                    'Delete Your Words Against The Clock Account?'
                  );
                }}
              >
                <IonIcon icon={trash} />
                Delete Account
              </IonButton>
            </IonItem>
          </IonList>
          <LegalLinks />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default Logout;
