import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonContent,
  IonCol,
  IonGrid,
  IonRow,
} from '@ionic/react';
import { ModalProps } from '../types';

const ScoringGuide: React.FC<ModalProps> = (props: ModalProps) => {
  const { closeHandler } = props;

  return (
    <>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonTitle>Scoring Guide</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => closeHandler()}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div>
          <ul>
            <li>
              The amount of points that you earn per word depends on the length
              of the word that was entered.
            </li>
            <li>
              Longer words yield much more points than shorter words, so go for
              the bigger words when you can!
            </li>
          </ul>
        </div>
        <div>
          <IonGrid className="ion-text-center">
            <IonRow>
              <IonCol>
                <strong>Word Length</strong>
              </IonCol>
              <IonCol>
                <strong>Point Value</strong>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>2 letters</IonCol>
              <IonCol>3 points</IonCol>
            </IonRow>
            <IonRow>
              <IonCol>3 letters</IonCol>
              <IonCol>6 points</IonCol>
            </IonRow>
            <IonRow>
              <IonCol>4 letters</IonCol>
              <IonCol>9 points</IonCol>
            </IonRow>
            <IonRow>
              <IonCol>5 letters</IonCol>
              <IonCol>14 points</IonCol>
            </IonRow>
            <IonRow>
              <IonCol>6 letters</IonCol>
              <IonCol>20 points</IonCol>
            </IonRow>
            <IonRow>
              <IonCol>7 letters</IonCol>
              <IonCol>32 points</IonCol>
            </IonRow>
            <IonRow>
              <IonCol>8 letters</IonCol>
              <IonCol>48 points</IonCol>
            </IonRow>
            <IonRow>
              <IonCol>9 letters</IonCol>
              <IonCol>72 points</IonCol>
            </IonRow>
            <IonRow>
              <IonCol>10+ letters</IonCol>
              <IonCol>100 points</IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </>
  );
};

export default ScoringGuide;
