import { atom } from 'recoil';
import { initialLeaderboardData, initialMyStats } from '../data';
import { LeaderboardState, MyStatsState } from '../types';

export const myStats = atom({
  key: 'my-stats',
  default: initialMyStats as MyStatsState,
});

export const leaderboardStats = atom({
  key: 'leaderboardStats',
  default: initialLeaderboardData as LeaderboardState,
});
