/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  useIonLoading,
} from '@ionic/react';
import { refresh, star } from 'ionicons/icons';
import { leaderboardStats, userState } from '../atoms';
import { useRecoilState } from 'recoil';
import { useEffect } from 'react';
import { useAxios } from '../hooks';
import { LOADING_MESSAGE } from '../utils/constants';

const Leaderboard: React.FC = () => {
  const [leaderboardState, setLeaderboardState] =
    useRecoilState(leaderboardStats);
  const [userData] = useRecoilState(userState);
  const { leaderboardData } = leaderboardState;

  const username = userData?.username;

  const [presentLoader, dismissLoader] = useIonLoading();

  const [{ data, loading, error }, refetch] = useAxios({
    url: '/leaderboard',
    method: 'GET',
  });

  useEffect(() => {
    if (data) {
      setLeaderboardState({
        leaderboardData: data.leaderboard,
      });
    }

    if (loading && !error) {
      presentLoader({
        message: LOADING_MESSAGE,
      });
    } else {
      dismissLoader();
    }
  }, [data, loading, error]);

  if (error) {
    dismissLoader();
    return <p>We're unable to load the leaderboard</p>;
  }

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>
          Leaderboard
          <IonButton
            color="tertiary"
            className="stats-table__refresh-button"
            onClick={() => refetch()}
          >
            <IonIcon icon={refresh} />
          </IonButton>
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonGrid>
          {leaderboardData?.length ? (
            <IonRow>
              <IonCol size="3">
                <p className="stats-table__header">Rank</p>
              </IonCol>
              <IonCol size="5">
                <p className="stats-table__header">Player</p>
              </IonCol>
              <IonCol size="4">
                <p className="stats-table__header ion-text-right">Best Score</p>
              </IonCol>
            </IonRow>
          ) : null}
          {leaderboardData?.length ? (
            leaderboardData.map((item, index) => {
              return (
                <IonRow
                  key={`leaderboard-item-${index}`}
                  className={
                    username && username === item.username
                      ? 'leaderboard__active-user'
                      : ''
                  }
                >
                  <IonCol size="3">
                    {leaderboardData.length > 1 ? index + 1 : '-'}
                    {username && username === item.username ? (
                      <span>
                        <IonIcon icon={star} color="primary" />{' '}
                      </span>
                    ) : null}
                  </IonCol>
                  <IonCol size="5">{item.username}</IonCol>
                  <IonCol size="4" className="ion-text-right">
                    {item.bestScore}
                  </IonCol>
                </IonRow>
              );
            })
          ) : (
            <p>We're unable to load the leaderboard</p>
          )}
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

export default Leaderboard;
