export const letterSquareIds = [
  'letter-square-11',
  'letter-square-12',
  'letter-square-13',
  'letter-square-14',
  'letter-square-15',
  'letter-square-21',
  'letter-square-22',
  'letter-square-23',
  'letter-square-24',
  'letter-square-25',
  'letter-square-31',
  'letter-square-32',
  'letter-square-33',
  'letter-square-34',
  'letter-square-35',
  'letter-square-41',
  'letter-square-42',
  'letter-square-43',
  'letter-square-44',
  'letter-square-45',
  'letter-square-51',
  'letter-square-52',
  'letter-square-53',
  'letter-square-54',
  'letter-square-55',
];
