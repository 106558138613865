import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonImg,
  IonIcon,
  IonLabel,
  IonButton,
  IonModal,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import { personCircle } from 'ionicons/icons';
import { HowToPlay, Login, ScoringGuide } from '../components';
import { useState } from 'react';
import Logout from '../components/Logout';
import { useRecoilState } from 'recoil';
import { userState } from '../atoms';
import { useHistory } from 'react-router-dom';
// import InstallPWA from '../components/InstallPWA';

const Home: React.FC = () => {
  const history = useHistory();
  const [user] = useRecoilState(userState);
  const [showHowToPlayModal, setShowHowToPlayModal] = useState(false);
  const [showScoringGuideModal, setShowScoringGuideModal] = useState(false);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <IonIcon
              className="header__player-icon"
              size="large"
              icon={personCircle}
            />
            <IonLabel className="header__player-name-label">
              {user.username || 'Guest'}
            </IonLabel>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {/* <div style={{ position: 'absolute', right: 0 }}>
          <InstallPWA />
        </div> */}
        <IonImg
          src={'assets/icon/watc-text-logo.png'}
          alt="Words Against The Clock logo"
          className="watc-logo"
        />
        <IonGrid>
          <IonRow>
            <IonCol
              size="10"
              offset="1"
              sizeMd="6"
              offsetMd="3"
              sizeLg="4"
              offsetLg="4"
            >
              <IonButton
                id="how-to-play-button"
                size="default"
                color="tertiary"
                expand="block"
                onClick={() => {
                  setShowHowToPlayModal(true);
                }}
              >
                How To Play
              </IonButton>

              <IonButton
                id="scoring-guide-button"
                size="default"
                color="tertiary"
                expand="block"
                onClick={() => {
                  setShowScoringGuideModal(true);
                }}
              >
                Scoring Guide
              </IonButton>
              <IonButton
                id="play-now-button"
                size="default"
                color="primary"
                expand="block"
                onClick={() => {
                  history.push('/play');
                }}
              >
                Play Game
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        {user.username ? <Logout /> : <Login />}
        <div className="copyright-text">
          <p>&copy; 2022 CommendCode LLC</p>
        </div>
      </IonContent>
      {/* Modals */}
      <IonModal
        keyboardClose={true}
        isOpen={showHowToPlayModal}
        onDidDismiss={() => setShowHowToPlayModal(false)}
      >
        <HowToPlay closeHandler={() => setShowHowToPlayModal(false)} />
      </IonModal>
      <IonModal
        keyboardClose={true}
        isOpen={showScoringGuideModal}
        onDidDismiss={() => setShowScoringGuideModal(false)}
      >
        <IonContent>
          <ScoringGuide closeHandler={() => setShowScoringGuideModal(false)} />
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default Home;
