export const secondsToTimer = (seconds: number) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.round(seconds % 60);
  return [h, m > 9 ? m : h ? '0' + m : m || '0', s > 9 ? s : '0' + s]
    .filter(Boolean)
    .join(':');
};

export const enterKeyHandler = (
  eventKey: string,
  functionToExecute: Function
) => {
  if (eventKey === 'Enter') {
    functionToExecute();
  }
};

export const getRandomAmazonAdSearchPhrase = () => {
  const searchPhraseList = [
    'Amazon Echo',
    'Sunglasses',
    'Kindle Fire',
    'Top Rated TVs',
    'Top Rated Laptops',
    'Phone Charger Cables',
    'Wireless Charging Pads',
    'Popular Items',
  ];

  const randomIndex = Math.floor(Math.random() * searchPhraseList.length);

  return searchPhraseList[randomIndex];
};

export const playAudio = (elementId: string) => {
  const audioTag = document.getElementById(elementId) as HTMLAudioElement;

  if (audioTag) {
    audioTag.play();
  }
};
