/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
} from '@ionic/react';
import { useRecoilState } from 'recoil';
import { secondsToTimer } from '../utils';
import { gameData, gameTime } from '../atoms';
import CurrentWordBar from '../components/CurrentWordBar';
import PregameCard from '../components/PregameCard';
import GameBoard from '../components/GameBoard';
import { GameStatus } from '../types';
import { useEffect } from 'react';
import PostgameReport from '../components/PostgameReport';
import { AmazonAd } from '../components';
import { ENABLE_AMAZON_ADS } from '../utils/constants';

const Play: React.FC = () => {
  const [gameState, setGameState] = useRecoilState(gameData);
  const [gameTimeState, setGameTimeState] = useRecoilState(gameTime);

  const { score, status } = gameState;
  const { secondsLeft } = gameTimeState;
  const timeLeft = secondsToTimer(secondsLeft);
  let timer = setTimeout(() => {}, 0);

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (secondsLeft <= 0 && status === GameStatus.Active) {
      setGameState({
        ...gameState,
        status: GameStatus.PostGame,
      });
    } else if (secondsLeft > 0 && status === GameStatus.Active) {
      timer = setTimeout(() => {
        setGameTimeState({
          secondsLeft: secondsLeft - 1,
        });
      }, 1000);
    } else if (status === GameStatus.EndEarly) {
      clearTimeout(timer);

      timer = setTimeout(() => {
        setGameTimeState({
          secondsLeft: 0,
        });
      }, 0);

      setGameState({
        ...gameState,
        gameId: '',
        status: GameStatus.PreGame,
        score: 0,
        currentWord: '',
      });
    }
  }, [secondsLeft, status]);

  const endGame = () => {
    setGameState({
      ...gameState,
      status: GameStatus.EndEarly,
    });
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="game-hud">
          <IonGrid>
            {status === GameStatus.Active ? (
              <IonRow>
                <IonCol>
                  <p> Time Left: {timeLeft}</p>
                </IonCol>
                <IonCol>
                  <p>Score: {score}</p>
                </IonCol>
                <IonCol>
                  <IonButton color="danger" onClick={endGame}>
                    End Game
                  </IonButton>
                </IonCol>
              </IonRow>
            ) : (
              <IonRow></IonRow>
            )}
          </IonGrid>
        </div>
        <div className="game-container">
          {status === GameStatus.Active ? (
            <>
              <GameBoard />
              <CurrentWordBar />
            </>
          ) : status === GameStatus.PreGame ||
            status === GameStatus.EndEarly ? (
            <>
              <PregameCard />
            </>
          ) : (
            <>
              <PostgameReport />{' '}
              {ENABLE_AMAZON_ADS ? (
                <div
                  className="ion-padding"
                  style={{ borderTop: '1px solid gray' }}
                >
                  <AmazonAd />
                </div>
              ) : null}
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Play;
