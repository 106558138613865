import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { letterSquareIds } from '../data';
import LetterSquare from './LetterSquare';

const GameBoard: React.FC = () => {
  const firstRowSquares = letterSquareIds.slice(0, 5);
  const secondRowSquares = letterSquareIds.slice(5, 10);
  const thirdRowSquares = letterSquareIds.slice(10, 15);
  const fourthRowSquares = letterSquareIds.slice(15, 20);
  const fifthRowSquares = letterSquareIds.slice(20, 25);

  return (
    <IonGrid>
      <IonRow>
        <IonCol sizeMd="8" offsetMd="2" sizeLg="6" offsetLg="3">
          <IonRow>
            {firstRowSquares.map((item) => {
              return (
                <IonCol key={item}>
                  <LetterSquare />
                </IonCol>
              );
            })}
          </IonRow>
          <IonRow>
            {secondRowSquares.map((item) => {
              return (
                <IonCol key={item}>
                  <LetterSquare />
                </IonCol>
              );
            })}
          </IonRow>
          <IonRow>
            {thirdRowSquares.map((item) => {
              return (
                <IonCol key={item}>
                  <LetterSquare />
                </IonCol>
              );
            })}
          </IonRow>
          <IonRow>
            {fourthRowSquares.map((item) => {
              return (
                <IonCol key={item}>
                  <LetterSquare />
                </IonCol>
              );
            })}
          </IonRow>
          <IonRow>
            {fifthRowSquares.map((item) => {
              return (
                <IonCol key={item}>
                  <LetterSquare />
                </IonCol>
              );
            })}
          </IonRow>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default GameBoard;
