/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonList,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonLoading,
} from '@ionic/react';
import { clipboard } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { LegalLinks } from '../components';
import { registerWithEmailAndPassword } from '../firebase';
import { useAxios } from '../hooks';
import { enterKeyHandler } from '../utils';
import {
  ALPHANUMERIC_REGEX,
  CREATE_ACCOUNT_LOADING_MESSAGE,
  EMAIL_REGEX,
} from '../utils/constants';

const CreateAccount: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const history = useHistory();
  const [presentAlert] = useIonAlert();
  const [presentLoader, dismissLoader] = useIonLoading();

  // Service call to check if a username is available
  const [
    { data: usernameCheckData, error: isUsernameCheckInError },
    executeUsercheck,
  ] = useAxios(
    {
      url: `/usernameCheck?username=${username}`,
      method: 'GET',
    },
    { manual: true }
  );

  // Function to create a new account
  const createAccount = async () => {
    const alphanumericRegex = new RegExp(ALPHANUMERIC_REGEX);
    const emailRegex = new RegExp(EMAIL_REGEX);

    if (!username) {
      presentAlert({
        header: 'Alert',
        message: 'Please enter a username',
        buttons: ['OK'],
      });

      return;
    }

    if (!alphanumericRegex.test(username)) {
      presentAlert({
        header: 'Alert',
        message:
          'Username is invalid. Usernames can only contain letters and numbers.',
        buttons: ['OK'],
      });

      return;
    }

    if (!email) {
      presentAlert({
        header: 'Alert',
        message: 'Please enter your email address',
        buttons: ['OK'],
      });

      return;
    }

    if (!emailRegex.test(email)) {
      presentAlert({
        header: 'Alert',
        message: 'Please enter a valid email address',
        buttons: ['OK'],
      });

      return;
    }

    if (!password) {
      presentAlert({
        header: 'Alert',
        message: 'Please enter a password',
        buttons: ['OK'],
      });

      return;
    }

    presentLoader({
      message: CREATE_ACCOUNT_LOADING_MESSAGE,
    });

    await executeUsercheck().catch(async (error) => {
      await dismissLoader();
    });
  };

  useEffect(() => {
    if (
      isUsernameCheckInError ||
      (usernameCheckData && !usernameCheckData.isUsernameAvailable)
    ) {
      dismissLoader();

      const message = isUsernameCheckInError
        ? "We're unable to verify that username. Please try again."
        : 'That username is unavailable. Please try a different username.';
      presentAlert({
        header: 'Alert',
        message,
        buttons: ['OK'],
      });
    } else if (usernameCheckData?.isUsernameAvailable) {
      const registerNewAccount = async () => {
        const registrationResult = await registerWithEmailAndPassword(
          username,
          email,
          password
        );

        dismissLoader();

        if (registrationResult.status === 'SUCCESS') {
          presentAlert({
            header: 'Success',
            message: 'Your new account has been created!',
            buttons: ['OK'],
          });

          history.push('/home');
        } else {
          presentAlert({
            header: 'Alert',
            message:
              registrationResult.message ||
              'Something went wrong. Please try again.',
            buttons: ['OK'],
          });
        }
      };

      registerNewAccount().catch((e) => {
        console.error(e);
      });
    }
  }, [usernameCheckData, isUsernameCheckInError]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Create Account</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonImg
          src={'assets/icon/watc-text-logo.png'}
          alt="Words Against The Clock logo"
          className="watc-logo"
        />
        <IonGrid>
          <IonRow>
            <IonCol
              size="10"
              offset="1"
              sizeMd="6"
              offsetMd="3"
              sizeLg="4"
              offsetLg="4"
            >
              <IonList>
                <IonItem>
                  <IonInput
                    type="text"
                    name="username"
                    value={username}
                    onIonChange={(e) => {
                      setUsername((e.target as HTMLInputElement).value);
                    }}
                    placeholder="Username"
                    autocomplete="off"
                    onKeyUp={(e) => enterKeyHandler(e.key, createAccount)}
                    maxlength={20}
                  />
                </IonItem>
                <IonItem>
                  <IonInput
                    type="email"
                    name="email"
                    value={email}
                    onIonChange={(e) =>
                      setEmail((e.target as HTMLInputElement).value)
                    }
                    placeholder="E-mail Address"
                    autocomplete="off"
                    onKeyUp={(e) => enterKeyHandler(e.key, createAccount)}
                  />
                </IonItem>
                <IonItem>
                  <IonInput
                    type="password"
                    value={password}
                    onIonChange={(e) =>
                      setPassword((e.target as HTMLInputElement).value)
                    }
                    placeholder="Password"
                    autocomplete="off"
                    onKeyUp={(e) => enterKeyHandler(e.key, createAccount)}
                  />
                </IonItem>
                <IonItem>
                  <IonButton
                    size="default"
                    onClick={createAccount}
                    type="submit"
                  >
                    <IonIcon icon={clipboard} />
                    Create Account
                  </IonButton>
                </IonItem>
              </IonList>
              <div className="ion-padding-top ion-text-center">
                <p>
                  Already have an account? &nbsp;
                  <Link to={'/home'}>Login</Link>
                </p>
                <LegalLinks />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default CreateAccount;
