/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  useIonLoading,
} from '@ionic/react';
import { refresh } from 'ionicons/icons';
import { useRecoilState } from 'recoil';
import { myStats, userState } from '../atoms';
import { useEffect } from 'react';
import { useAxios } from '../hooks';
import { ENABLE_AMAZON_ADS, LOADING_MESSAGE } from '../utils/constants';
import { getIdTokenFromLocalStorage } from '../firebase';
import { AmazonAd, GuestStats } from '.';

const MyStats: React.FC = () => {
  const [myStatsState, setMyStatsState] = useRecoilState(myStats);
  const { avgScore, bestScore, gamesPlayed } = myStatsState;
  const [userData] = useRecoilState(userState);
  const [presentLoader, dismissLoader] = useIonLoading();

  const [{ data, loading, error }, fetchMyStats] = useAxios(
    {
      url: '/stats',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getIdTokenFromLocalStorage()}`,
      },
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (userData.username && userData.email) {
      fetchMyStats();
    }
  }, []);

  useEffect(() => {
    if (data) {
      setMyStatsState({
        avgScore: data?.stats?.avgScore,
        bestScore: data?.stats?.bestScore,
        gamesPlayed: data?.stats?.gamesPlayed,
      });
    }
    if (loading && !error) {
      presentLoader({ message: LOADING_MESSAGE });
    } else {
      dismissLoader();
    }
  }, [data, loading, error]);

  if (!userData.username) return <GuestStats />;
  if (error) {
    dismissLoader();
    return <p>We're unable to load your stats</p>;
  }
  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            My Stats
            <IonButton
              color="tertiary"
              className="stats-table__refresh-button"
              onClick={() => fetchMyStats()}
            >
              <IonIcon icon={refresh} />
            </IonButton>
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonGrid>
            {myStatsState ? (
              <IonRow>
                <IonCol>
                  <p className="stats-table__header">Best Score</p>
                  <p className="mystats-row">{bestScore}</p>
                </IonCol>
                <IonCol>
                  <p className="stats-table__header">Average Score</p>
                  <p className="mystats-row">{avgScore}</p>
                </IonCol>
                <IonCol>
                  <p className="stats-table__header">Games Played</p>
                  <p className="mystats-row">{gamesPlayed}</p>
                </IonCol>
              </IonRow>
            ) : (
              <p>We're unable to load your stats</p>
            )}
          </IonGrid>
        </IonCardContent>
      </IonCard>
      {ENABLE_AMAZON_ADS ? (
        <div className="ion-padding" style={{ borderTop: '1px solid gray' }}>
          <AmazonAd />
        </div>
      ) : null}
    </>
  );
};

export default MyStats;
