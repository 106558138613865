/* eslint-disable react-hooks/exhaustive-deps */
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonImg,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { home, play, statsChart } from 'ionicons/icons';
import { useAxios } from './hooks';
import Home from './pages/Home';
import Play from './pages/Play';
import Stats from './pages/Stats';
import CreateAccount from './pages/CreateAccount';
import ResetPassword from './pages/ResetPassword';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './styles/app.css';
import { useRecoilState } from 'recoil';
import { userState } from './atoms';
import { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, getIdTokenFromLocalStorage } from './firebase';
// import { LOADING_MESSAGE } from './utils/constants';

setupIonicReact();

const App: React.FC = () => {
  const [, loading] = useAuthState(auth);
  const [, setUserData] = useRecoilState(userState);
  const [{ data, loading: loadingUserData }, executeGetUser] = useAxios(
    {
      url: '/user',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getIdTokenFromLocalStorage()}`,
      },
    },
    { manual: true }
  );

  useEffect(() => {
    // If there's an ID token already saved in sessionStorage, then refresh the user data.
    if (getIdTokenFromLocalStorage()) {
      executeGetUser();
    }
  }, []);

  useEffect(() => {
    if (data?.user) {
      setUserData({
        email: data?.user?.email,
        username: data?.user?.username,
      });
    }
  }, [data]);

  if (loadingUserData || loading)
    return (
      <>
        <IonImg
          src={'assets/icon/watc-text-logo.png'}
          alt="Words Against The Clock logo"
          className="watc-logo"
        />
        <p>Loading...</p>
      </>
    );

  return (
    <IonApp>
      <h1 style={{ display: 'none' }}>Words Against The Clock</h1>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route exact path="/home">
              <Home />
            </Route>
            <Route exact path="/play">
              <Play />
            </Route>
            <Route path="/stats">
              <Stats />
            </Route>
            <Route path="/create-account">
              <CreateAccount />
            </Route>
            <Route path="/reset-password">
              <ResetPassword />
            </Route>
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton tab="Home" href="/home">
              <IonIcon icon={home} />
              <IonLabel>Home</IonLabel>
            </IonTabButton>
            <IonTabButton tab="Play" href="/play">
              <IonIcon icon={play} />
              <IonLabel>Play</IonLabel>
            </IonTabButton>
            <IonTabButton tab="Stats" href="/stats">
              <IonIcon icon={statsChart} />
              <IonLabel>Stats</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
