import { makeUseAxios } from 'axios-hooks';
import axios from 'axios';
import { getIdTokenFromLocalStorage } from '../firebase';

export const useAxios = makeUseAxios({
  axios: axios.create({
    baseURL: process.env.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL || '',
    headers: {
      Authorization: `Bearer ${getIdTokenFromLocalStorage()}`,
    },
  }),
});
