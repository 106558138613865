/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { getRandomAmazonAdSearchPhrase } from '../utils';

interface AmazonAdProps {
  searchPhrase?: string;
  title?: string;
}

const AmazonAd: React.FC<AmazonAdProps> = (props: AmazonAdProps) => {
  const { searchPhrase, title } = props;
  const activeSearchPhrase = searchPhrase ?? getRandomAmazonAdSearchPhrase();
  const activeTitle = title ?? `Shop for ${activeSearchPhrase} on Amazon`;

  useEffect(() => {
    const script1 = document.createElement('script');
    script1.type = 'text/javascript';
    const scriptCode = `amzn_assoc_placement = 'adunit0';
        amzn_assoc_tracking_id = 'commendcode-20';
        amzn_assoc_ad_mode = 'search';
        amzn_assoc_ad_type = 'smart';
        amzn_assoc_marketplace = 'amazon';
        amzn_assoc_region = 'US';
        amzn_assoc_default_search_phrase = "${activeSearchPhrase}";
        amzn_assoc_default_category = 'All';
        amzn_assoc_linkid = '68eb2befbbe1dbdd8254a16acd113847';
        amzn_assoc_search_bar = 'true';
        amzn_assoc_search_bar_position = 'bottom';
        amzn_assoc_title = "${activeTitle}";
        amzn_assoc_rows = '2';
        amzn_assoc_div_name = "amzn_assoc_ad_div_adunit0_0"`;
    script1.appendChild(document.createTextNode(scriptCode));
    script1.id = 'amazon-ad-script-1';

    const script2 = document.createElement('script');
    script2.id = 'amazon-ad-script-2';
    script2.src = '//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US';
    script2.async = true;

    const componentDom = document.getElementById('amazon-search-container');
    if (componentDom) {
      if (!document.getElementById('amazon-ad-script-1')) {
        componentDom.appendChild(script1);
      }
      if (!document.getElementById('amazon-ad-script-2')) {
        componentDom.appendChild(script2);
      }
    }

    return () => {
      if (document.getElementById('amazon-ad-script-1')) {
        componentDom?.removeChild(script1);
      }
      if (document.getElementById('amazon-ad-script-2')) {
        componentDom?.removeChild(script2);
      }
    };
  }, [searchPhrase, title]);

  return (
    <div id="amazon-ads-container">
      <div id="amazon-search-container"></div>
      <div id="amzn_assoc_ad_div_adunit0_0"></div>
    </div>
  );
};

export default AmazonAd;
