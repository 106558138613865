/* eslint-disable react-hooks/exhaustive-deps */
import { IonRippleEffect } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { gameData } from '../atoms';
import { useGame } from '../hooks';
// import { playAudio } from '../utils';

const LetterSquare: React.FC = () => {
  const { getRandomLetter } = useGame();
  const [gameState, setGameState] = useRecoilState(gameData);
  const [isEnabled, setIsEnabled] = useState(true);
  const [letter, setLetter] = useState(getRandomLetter());
  const isCancelled = useRef(false);
  let timer: NodeJS.Timeout;

  useEffect(() => {
    timer = setTimeout(() => {}, 0);

    return () => {
      isCancelled.current = true;
      clearTimeout(timer);
    };
  }, []);

  const letterSquareClick = () => {
    if (isEnabled && !isCancelled.current) {
      // Play sound effect
      // const randomIndex = Math.floor(Math.random() * 5) + 1;
      //  playAudio(`keyboard-sound-audio-tag-${randomIndex}`);

      // Add letter to the current word
      setGameState({
        ...gameState,
        currentWord: gameState.currentWord + letter,
      });

      // Disable the square
      setIsEnabled(false);

      // Reenabled the square and assign a new letter after 5 seconds
      timer = setTimeout(() => {
        // Do not try to update state if component is unmounted
        if (!isCancelled.current) {
          setIsEnabled(true);
          setLetter(getRandomLetter());
        }
      }, 5000);
    }
  };

  return (
    <div
      className={`${
        !isEnabled ? 'letter-square--disabled' : ''
      } letter-square ion-activatable ripple-parent`}
      onClick={letterSquareClick}
    >
      {letter}
      {isEnabled ? <IonRippleEffect /> : null}
    </div>
  );
};

export default LetterSquare;
